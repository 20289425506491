body {
	margin: 0 !important;
	padding: 0 !important;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.container-fluid {
	margin: 0 !important;
	padding: 0px !important;
	max-width: 960px;
	width: auto;
	margin: 0 auto !important;
}

.footer {
	padding-top: 20px;
	padding-bottom: 20px;
}

.header {
	padding-top: 20px;
	padding-bottom: 20px;
}

.coming-soon {
	font-size: 30px;
	font-weight: 700;
}

.home-tagline {
	padding-top: 30px;
	font-size: 20px;
	font-weight: 600;
	font-style: italic;
}

.passwordreset-container {
	text-align: center;
}

.passwordreset-form {
	min-width: 300px;
	text-align: left;
	display: inline-block;
	vertical-align: middle;
}

.form-section-submit {
	padding-top: 20px;
}

.terms-and-conditions {
	padding-left: 14px;
	padding-right: 14px;
}

.app-policy {
	padding-left: 14px;
	padding-right: 14px;
}

.app-policy .title {
	font-size: 32px;
	font-weight: 700;
	padding-bottom: 10px;
}

.app-policy h1 {
	font-size: 30px;
	font-weight: 650;
}

.app-policy h2 {
	font-size: 28px;
	font-weight: 600;
}

.app-policy h3 {
	font-size: 26px;
	font-weight: 550;
}

.app-policy h4 {
	font-size: 24px;
	font-weight: 500;
}

.app-policy h5 {
	font-size: 22px;
	font-weight: 450;
}